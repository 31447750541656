<template>
    <div class="change_email">
        <div class="title">Change Email</div>
        <div class="main">
            
            <div class="input">
                <input type="text" placeholder="Enter new email address" v-model="email">
                <div class="err" v-if="e">This email has already been used</div>
            </div>

            <loading :height="'41px'" v-if="loading"/>
            <div class="btn" v-else @click="change()">Change</div>
        </div>
    </div>
</template>

<script>
import loading from '../loading.vue';
export default {
    components: {
        loading
    },
    data() {
        return {
            email: '',
            e: false,
            loading: false
        }
    },
    methods: {
        change() {
            if (this.email == '') {
                this.empty(); return 
            }

            this.loading = true
            this.$emit('locked')

            let form = new FormData()
            form.append('email', this.email)

            this.$http.post('/user/change_email', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    this.$root.$data.email = this.email

                    this.$emit('close')
                } else {
                    if (r.invalid) {
                        this.invalid()
                    }
                    if (r.email) {
                        this.e = true
                    }
                }
                this.loading = false
                this.$emit('unlock')
            })
        },

    },
    notifications: {
        empty: {
            'type': 'warn',
            'title': 'Email',
            'message': 'Please enter your new email'
        },
        invalid: {
            'type': 'warn',
            'title': 'Invalid',
            'message': 'The email you entered is invalid'
        },

        success: {
            'type': 'success',
            'title': 'Success',
            'message': 'Your email has been changed successfully'
        }
    }
}
</script>

<style scoped>

    .change_email {
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
        position: relative;
        z-index: 2;
        transition: .2s;
    }
    .title {
        padding: 20px;
        border-bottom: 1px solid #ddd;
        font-weight: 500;
    }
    .main {
        height: fit-content;
        padding: 20px;
        box-sizing: border-box;
        overflow: auto;
    }

    
    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -15px;
        left: 10px;
        font-size: 14px;
        font-weight: 500;
        padding: 0px 5px;
    }
    input {
        border: 1px solid #ddd;
        background-color: transparent;
        border-radius: 5px;
        outline: none;
        padding: 15px;
        width: 100%;
        box-sizing: border-box;
    }
    .err {
        font-size: 10px;
        font-weight: bold;
        color: tomato;
        position: absolute;
        bottom: 3px;
        right: 5px;
    }
    .btn {
        background-color: var(--main);
        padding: 10px;
        text-align: center;
        border-radius: 5px;
        font-weight: 500;
        color: white;
    }
</style>
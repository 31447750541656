<template>
    <div class="veirfy">
        <div class="logo">
            <img src="../../assets/ikotun_logo_2.png"/>
        </div>
        <div class="main">
            <div class="form">
                <div class="title">One More Step</div>
                <div class="text">
                    Enter the verification code sent your email address 
                    ({{$root.$data.email}} - 
                    <span>Resend Code</span> - 
                    <span @click="togglepop('show_change_email')">Change Email</span>)
                    and complete your details below
                </div>
                <div class="input">
                    <div class="label">Code</div>
                    <input type="text" placeholder="Enter email verification code" v-model="code">
                    <div class="err" v-if="c">Incorrect code</div>
                </div>
                <div class="input">
                    <div class="label">Firstname</div>
                    <input type="text" placeholder="Enter firstname" v-model="firstname">
                </div>
                <div class="input">
                    <div class="label">Lastname</div>
                    <input type="text" placeholder="Enter lastname" v-model="lastname">
                </div>
                <div class="input">
                    <div class="label">Gender</div>
                    <select v-model="gender">
                        <option disabled selected value="">Select your gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>
                </div>
                <loading v-if="loading" :height="'43px'"/>
                <div class="btn" v-else @click="verify()">Complete</div>
            </div>
        </div>
        
        <div class="popup" v-if="pop">
            <div class="dark" @click="closepop()"></div>

            <transition name="slide">
                <change_email 
                    @locked="locked = true" @unlock="locked = false" @close="close_change_email()"
                    v-if="show_change_email"/>
            </transition>
        </div>
    </div>
</template>

<script>
import Localbase from 'localbase'
let db = new Localbase('ikotun')

import change_email from '../../components/verify/change_email.vue';
import loading from '../../components/loading.vue';

export default {
    components: {
        change_email,
        loading
    },
    created() {
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            if (this.locked) { next(false); return  }
            if (this.pop) {
                this.closepop()
                next(false)
            } else {
                next()
            }
        })

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })
    },
    mounted() {
        setTimeout(() => {
            this.h = window.innerHeight + 'px'
        }, 1000);
        this.send_code()
    },
    data() {
        return {
            locked: false,
            pop: false,
            show_change_email: false,

            loading: false,
            code: '',
            firstname: '',
            lastname: '',
            gender: '',
            c: false,

            h: ''
        }
    },
    methods: {
        togglepop(what) {
            this.pop = true
            setTimeout(() => {
                this[what] = true
            }, 100);
        },
        closepop() {
            if (this.locked) { return }
            this.show_change_email = false
            setTimeout(() => {
                this.pop = false
            }, 200);
        },

        send_code() {
            this.$http.post('/user/send_code')
        },
        close_change_email() {
            this.locked = false

            this.closepop()
            this.send_code()
        },

        verify() {
            if (this.code == '' || this.firstname == '' || this.lastname == '' || this.gender == '') {
                this.empty(); return
            }

            this.loading = true
            this.c = false

            let form = new FormData()
            form.append('code', this.code)
            form.append('firstname', this.firstname)
            form.append('lastname', this.lastname)
            form.append('gender', this.gender)

            this.$http.post('/user/verify', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.$root.$data.created = r.created
                    this.$root.$data.verified = r.verified
                    this.$root.$data.dp = r.dp
                    this.$root.$data.cover = r.cover
                    this.$root.$data.firstname = this.firstname
                    this.$root.$data.lastname = this.lastname
                    this.$root.$data.gender = this.gender
                    this.$root.$data.location = r.location
                    this.$root.$data.posts = 0
                    this.$root.$data.comments = 0
                    this.$root.$data.followers = 0
                    this.$root.$data.followings = 0
                    
                    db.collection('auth').doc({ id: 1 }).update({
                        email_verified: true,
                        created: r.created,
                        verified: r.verified,
                        dp: r.dp,
                        cover: r.cover,
                        firstname: this.firstname,
                        lastname: this.lastname,
                        gender: this.gender,
                        location: r.location,
                        posts: 0,
                        comments: 0,
                        followers: 0,
                        followings: 0
                    }).then(() => {
                        this.$router.push('/home')
                    })
                } else {
                    if (r.code) {
                        this.c = true
                        this.wrong_code()
                    }
                }

                this.loading = false
            })
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: 'Empty',
            message: 'Some fields are empty'
        },
        wrong_code: {
            type: 'warn',
            title: 'Wrong code',
            message: 'The code you entered is incorrect'
        }
    }
}
</script>

<style scoped>

    .veirfy {

        background-size: cover;
        background-repeat: no-repeat;
        position: relative;

        display: grid;
        grid-template-rows: max-content auto;
        height: 100vh;
        background-color: white;
    }

    .logo {
        /* position: absolute; */
        /* top: -120px; */
        /* left: calc(50% - 100px); */
        /* background-color: rgba(0, 0, 0, 0.5); */
        /* width: 200px; */
        /* height: 200px; */
        padding: 30px;
        box-sizing: border-box;
        /* border-radius: 50%; */
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    .logo img {
        width: 100px;
    }

    .main {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px;
        box-sizing: border-box;
        /* height: 100vh; */
    }
    
    .form {
        width: 100%;
        /* border-radius: 20px; */
        /* padding: 30px; */
        box-sizing: border-box;
        /* background-color: rgba(0, 0, 0, 0.5); */
        /* color: white; */
    }
    .title {
        font-weight: 800;
        font-size: 20px;
        /* margin-bottom: 5px; */
    }
    .text {
        margin-bottom: 30px;
        font-size: 12px;
        /* color: #ccc; */
    }
    span {
        /* color: white; */
        font-weight: 700;
        opacity: 1;
        cursor: pointer;
    }
    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -10px;
        left: 10px;
        font-size: 14px;
        font-weight: 600;
        padding: 0px 5px;
        background-color: white;
    }
    input, select {
        border: 1px solid #ddd;
        background-color: transparent;
        border-radius: 5px;
        outline: none;
        padding: 15px;
        width: 100%;
        box-sizing: border-box;
    }
    option {
        color: black;
    }
    .err {
        font-size: 10px;
        font-weight: bold;
        color: tomato;
        position: absolute;
        bottom: 3px;
        right: 5px;
    }
    .btn {
        background-color: var(--main);
        padding: 12px;
        text-align: center;
        border-radius: 5px;
        font-weight: 500;
        color: white;
    }


    
    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        z-index: 3;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .slide-enter-active {
        animation: move .3s;
    }
    .slide-leave-active {
        animation: .2s move reverse;
    }
    @keyframes move {
        from {
            margin-bottom: -50vh;
        }
        to {
            margin-bottom: 0px;
        }
    }


    @media screen and (max-height: 600px) {
        .main, .form {
            padding: 20px;
        }

        .input, .text {
            margin-bottom: 20px;
        }
    }
</style>